import styled from 'styled-components'

import SelectBase from '../../Select'

export const SpeakerContainer = styled.div`
  padding-bottom: 20px;
  margin: 0 auto;
`

export const SpeakerTitle = styled.div`
  font-size: ${({ theme: { size } }) => size.fontSize.s};
  color: ${({ theme: { color } }) => color.secondary.main};
  padding-bottom: 10px;
  text-align: center;
`

export const Select = styled(SelectBase)`
  margin: 0 auto;
`
