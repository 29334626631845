import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { string, func } from 'prop-types'

import { grillclothData } from '../data'
import {
  ImagesCotainer, ItemContainer, ImgContainer, Name,
} from '../Teintes/styles'

const query = graphql`
  {
    black_and_tan_jtm: file(relativePath: {
      eq: "grillcloth/black_and_tan_jtm.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    british_black_and_white: file(relativePath: {
      eq: "grillcloth/british_black_and_white.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fender_gold: file(relativePath: {eq: "grillcloth/fender_gold.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fender_oxblood: file(relativePath: {eq: "grillcloth/fender_oxblood.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fender_silver: file(relativePath: {eq: "grillcloth/fender_silver.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    standard_black: file(relativePath: {eq: "grillcloth/standard_black.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    standard_cream: file(relativePath: {eq: "grillcloth/standard_cream.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    standard_white: file(relativePath: {eq: "grillcloth/standard_white.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    vintage_bluesbreaker: file(relativePath: {
      eq: "grillcloth/vintage_bluesbreaker.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const GrillCoth = ({ selectedId, onClick }) => {
  const data = useStaticQuery(query)

  const grillcloth = grillclothData.map(obj => ({
    ...obj,
    fixed: data[obj.id].childImageSharp.fixed,
  }))

  return (
    <ImagesCotainer>
      {
        grillcloth.map(({ id, label, fixed }) => (
          <ItemContainer
            onClick={() => { onClick(id) }}
            key={id}
          >
            <ImgContainer $isSelected={selectedId === id}>
              <Img fixed={fixed} />
            </ImgContainer>
            <Name>{label}</Name>
          </ItemContainer>
        ))
      }
    </ImagesCotainer>
  )
}

GrillCoth.defaultProps = {
  selectedId: null,
}

GrillCoth.propTypes = {
  selectedId: string,
  onClick: func.isRequired,
}

export default GrillCoth
