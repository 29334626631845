/* eslint-disable max-len */
import React from 'react'
import {
  ListContainer, ListTitle, IncludedList, ListLine,
} from './styles'

const List = () => (
  <ListContainer>
    <ListTitle>Le tarif de base inclut :</ListTitle>
    <IncludedList>
      <ListLine>Une teinte du bois de la couleur de votre choix (voir liste)</ListLine>
      <ListLine>Un vernis mat ou glossy</ListLine>
      <ListLine>8 corners noirs ou chrome</ListLine>
      <ListLine>une grillcoth (voir liste)</ListLine>
      <ListLine>2 poignées encastrables steel bar ou strap vox style noires</ListLine>
      <ListLine>Le/les haut-parleurs (voir liste)</ListLine>
      <ListLine>1 entrée mono + link</ListLine>
      <ListLine>4 pieds rubber foot 20mm</ListLine>
    </IncludedList>
  </ListContainer>
)

export default List
