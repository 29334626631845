import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { string, func } from 'prop-types'

import {
  ImagesContainer,
  Image, ImgContainer, OptionContainer, OptionName, OptionFog, TitleSection,
} from './styles'

const query = graphql`
  {
    cabsBass: file(relativePath: {eq: "musicgear/cabs-bass.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cabsGuitar: file(relativePath: {eq: "musicgear/cabs-guitar.jpg"}) {
      childImageSharp {
        fixed(width: 230, height: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const Images = ({ cab, onChange }) => {
  const data = useStaticQuery(query)

  return (
    <>
      <TitleSection>Choisissez votre cab</TitleSection>
      <ImagesContainer>
        <OptionContainer onClick={() => { onChange('bass') }}>
          <ImgContainer isSelected={cab === 'bass'}>
            <Image fixed={data.cabsBass.childImageSharp.fixed} />
            <OptionFog isSelected={cab === 'bass'} />
          </ImgContainer>
          <OptionName isSelected={cab === 'bass'}>Cab Bass</OptionName>
        </OptionContainer>
        <OptionContainer onClick={() => { onChange('guitar') }}>
          <ImgContainer isSelected={cab === 'guitar'}>
            <Image fixed={data.cabsGuitar.childImageSharp.fixed} />
            <OptionFog isSelected={cab === 'guitar'} />
          </ImgContainer>
          <OptionName isSelected={cab === 'guitar'}>Cab Guitar</OptionName>
        </OptionContainer>
      </ImagesContainer>
    </>
  )
}

Images.defaultProps = {
  cab: null,
}

Images.propTypes = {
  cab: string,
  onChange: func.isRequired,
}

export default Images
