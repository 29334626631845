/* eslint-disable max-len */
import capitalize from 'lodash.capitalize'
import {
  guitarOptions, bassOptions, additionalOptions,
} from './data'

import { grillclothData, speakers } from './data'

const getOptPrice = ({ cab, typeId }) => {
  const options = cab === 'bass' ? bassOptions : guitarOptions
  const choosenOpt = options.find(opt => opt.id === typeId)

  if (!choosenOpt) return null

  return choosenOpt.price
}

const getAdditionOptPrice = additionalOpt =>
  additionalOptions.reduce((acc, opt) => {
    if (additionalOpt.includes(opt.id)) {
      if (typeof opt.price === 'number') {
        return { ...acc, amount: acc.amount + opt.price }
      }
      return { ...acc, text: [...acc.text, opt.price] }
    }

    return acc
  }, { amount: 0, text: [] })

const getTeintGrillPrice = (biColor, grillclothId) => {
  const colorPrice = biColor ? 50 : 0
  const grillclothPrice = grillclothId
    ? grillclothData.find(g => g.id === grillclothId).price
    : 0

  return colorPrice + grillclothPrice
}

const getSpeakersPrice = (cab, typeId, speakersId) => {
  const options = cab === 'bass' ? bassOptions : guitarOptions
  const choosenOpt = options.find(opt => opt.id === typeId)
  if (!choosenOpt) {
    console.error('Cannot find choosen Type')
    return 0
  }

  return (choosenOpt.speakers || []).reduce((acc, size, index) => {
    const speakerOpt = speakers[cab][size]
    const flatOptions = speakerOpt.reduce(
      (acc, opt) => [...acc, ...opt.options], [],
    )

    const id = speakersId[index]

    const opt = flatOptions.find(opt => opt.id === id)

    if (!opt) {
      console.error(
        `Cannot find option.
        cab: ${cab}, typeId: ${typeId}, speakersId: ${speakersId.join(' ')}`,
      )
      return acc + 0
    }

    return acc + opt.price
  }, 0)
}

export const countTotal = ({
  cab, typeId, additionalOpt, biColor, grillclothId, speakersId,
}) => {
  if (!cab || !typeId) return null

  const optPrice = getOptPrice({ cab, typeId })
  if (optPrice === null) return null

  const additionOptPrice = getAdditionOptPrice(additionalOpt || [])

  const teintAndGrillPrice = getTeintGrillPrice(biColor, grillclothId)

  const speakersPrice = getSpeakersPrice(cab, typeId, speakersId)

  const amount =
    optPrice + additionOptPrice.amount + teintAndGrillPrice + speakersPrice

  return {
    amount,
    text: additionOptPrice.text,
  }
}

export const initSpeakersId = (cab, typeId) => {
  const options = cab === 'bass' ? bassOptions : guitarOptions
  const typeSpeaker = options.find(opt => opt.id === typeId)

  return typeSpeaker.speakers.map(
    size => speakers[cab][size][0].options[0].id,
  )
}

export const genDataForEmailDevi = ({
  cab, typeId, additionalOpt = [], biColor, grillclothId, speakersId = [], teintes,
}) => {
  const title = `Cab ${cab}`

  const typeOptions = cab === 'bass' ? bassOptions : guitarOptions
  const cabType = typeOptions.find(opt => opt.id === typeId)

  const choosenAdditionalOpt = additionalOptions.filter(opt => additionalOpt.includes(opt.id))

  const teintesNames = (teintes || []).map(id => id.split('_').map(str => capitalize(str)).join(' '))

  const choosenGrillcloth = grillclothData.find(g => g.id === grillclothId)

  const chooseSpeakersList = (cabType?.speakers || []).reduce((acc, size, index) => {
    const speakerOpt = speakers[cab][size]
    const flatOptions = speakerOpt.reduce(
      (acc, opt) => [...acc, ...opt.options], [],
    )

    const id = speakersId[index]
    const choosenSpeak = flatOptions.find(opt => opt.id === id)

    if (!choosenSpeak) {
      const data = {
        label: `Speaker ${size}"`,
        value: null,
      }
      return [...acc, data]
    }

    return [
      ...acc,
      { label: `Speaker ${size}"`, value: choosenSpeak.title },
    ]
  }, [])

  return {
    title,
    data: [
      {
        label: 'Type de cab',
        value: `speakers ${cabType?.type}, taille ${cabType?.size} (mm), prix ${cabType?.price}€`,
      },
      {
        label: 'Options supplémentaires',
        value: choosenAdditionalOpt?.length > 0
          ? choosenAdditionalOpt.map(opt => opt.label).join(', ')
          : 'Aucune',
      },
      {
        label: 'Teinte bois Bi-color',
        value: biColor,
      },
      {
        label: 'Teintes',
        value: teintesNames.join(' et '),
      },
      {
        label: 'Grill Coth',
        value: choosenGrillcloth?.label || '',
      },
      ...chooseSpeakersList,
    ],
  }
}
