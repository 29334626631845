import React from 'react'
import { string, func, arrayOf } from 'prop-types'

import { SpeakerContainer, SpeakerTitle, Select } from './styles'

import { speakers, guitarOptions, bassOptions } from '../data'

const Speakers = ({
  cab, typeId, onChange, selectedSpeakersId,
}) => {
  if (!cab || !typeId) return null

  const optionType = cab === 'bass' ? bassOptions : guitarOptions
  const speakersSize = optionType.find(opt => opt.id === typeId)?.speakers

  if (!speakersSize) return null

  return (
    <div>
      {
        speakersSize.map((spkSize, ind) => (
          // eslint-disable-next-line react/no-array-index-key
          <SpeakerContainer key={`${spkSize}_${ind}`}>
            <SpeakerTitle>{`Speaker ${spkSize}"`}</SpeakerTitle>
            <Select
              hasGroup
              value={selectedSpeakersId[ind]}
              onChange={onChange(ind)}
              options={speakers[cab][spkSize]}
            />
          </SpeakerContainer>
        ))
      }
    </div>
  )
}

Speakers.defaultProps = {
  cab: null,
  typeId: null,
}

Speakers.propTypes = {
  cab: string,
  typeId: string,
  selectedSpeakersId: arrayOf(string).isRequired,
  onChange: func.isRequired,

}

export default Speakers
