import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../../Layout'
import SEO from '../../SEO'

import CabsContent from '../../components/Cabs'

export const query = graphql`
  {
    file(relativePath: { eq: "musicgear/music-gear-home.jpg" }) {
      childImageSharp {
        resize {
          src
          width
          height
        }
      }
    }
  }
`

const CabsPage = ({ data }) => (
  <Layout withPadding={false}>
    <SEO
      title="Cabs"
      keywords={[
        'cab', 'cabs', 'bass', 'guitar', 'music', 'music gear',
      ]}
      image={data.file.childImageSharp.resize}
    />
    <CabsContent />
  </Layout>
)

CabsPage.propTypes = {
  data: shape({}).isRequired,
}

export default CabsPage
