/* eslint-disable react/state-in-constructor */
import React from 'react'

import Images from './Images'
import List from './List'
import Checkbox from '../Checkbox'
import PricePreviw from './PricePreviw'
import Teintes from './Teintes'
import GrillCoth from './GrillCoth'
import Speakers from './Speakers'
import Form, { Dialog, genDevis } from '../Form'
import PageImage from '../PageImage'

import {
  Content,
  Description,
  DevisTitle,
  TitleSection,
  Select,
  Section,
  CheckBoxWrapper,
} from './styles'

import {
  description, guitarOptions, bassOptions, additionalOptions,
} from './data'

import { countTotal, initSpeakersId, genDataForEmailDevi } from './helpers'

class CabsContent extends React.Component {
  state = {
    cab: null, // ['bass', 'guitar'],
    typeId: null,
    additionalOpt: [],
    biColor: false,
    teintes: [],
    grillclothId: null,
    speakersId: [],
    showForm: false,
  }

  onToggleForm = () => {
    const { showForm } = this.state
    this.setState({ showForm: !showForm })
  }

  onChangeCab = cab => {
    if (cab === 'bass') {
      this.setState({
        cab,
        typeId: bassOptions[7].id,
        speakersId: initSpeakersId(cab, bassOptions[7].id),
      })
    } else {
      this.setState({
        cab,
        typeId: guitarOptions[0].id,
        speakersId: initSpeakersId(cab, guitarOptions[0].id),
      })
    }
  }

  onChangeOption = e => {
    const typeId = e.target.value
    const { cab } = this.state
    this.setState({
      typeId,
      speakersId: initSpeakersId(cab, typeId),
    })
  }

  onChangeAdditionalOpt = id => () => {
    const { additionalOpt } = this.state
    if (additionalOpt.includes(id)) {
      this.setState({ additionalOpt: additionalOpt.filter(i => i !== id) })
    } else {
      this.setState({ additionalOpt: [...additionalOpt, id] })
    }
  }

  onSwitchDoubleTeint = () => {
    const { biColor, teintes } = this.state

    if (biColor) {
      const newTeintes = [teintes[0]].filter(Boolean)
      this.setState({ biColor: false, teintes: newTeintes })
    } else {
      this.setState({ biColor: true })
    }
  }

  onHandleDoubleTeints = name => {
    const { teintes } = this.state

    if (teintes.includes(name)) {
      this.setState({ teintes: teintes.filter(t => t !== name) })
    } else {
      const thereIsSpace = teintes.length < 2
      if (thereIsSpace) {
        this.setState({ teintes: [...teintes, name] })
      } else {
        this.setState({ teintes: [teintes[1], name] })
      }
    }
  }

  onHandleSingleTeint = name => {
    const { teintes } = this.state

    const isAlreadySelected = teintes.includes(name)
    if (isAlreadySelected) {
      this.setState({ teintes: [] })
    } else {
      this.setState({ teintes: [name] })
    }
  }

  onChangeTeint = name => {
    const { biColor } = this.state

    if (biColor) {
      this.onHandleDoubleTeints(name)
    } else {
      this.onHandleSingleTeint(name)
    }
  }

  onChangeGrillcloth = id => {
    this.setState({ grillclothId: id })
  }

  onChangeSpeaker = index => e => {
    const { speakersId } = this.state
    const id = e.target.value

    const newSpeakersId = [
      ...speakersId.slice(0, index),
      id,
      ...speakersId.slice(index + 1),
    ]

    this.setState({ speakersId: newSpeakersId })
  }

  render() {
    const {
      cab,
      typeId,
      additionalOpt, biColor, teintes, grillclothId, speakersId, showForm,
    } = this.state

    const cabOptions = cab === 'bass' ? bassOptions : guitarOptions
    const options = (cabOptions || []).map(opt => ({
      ...opt,
      title: `${opt.type} ${opt.price} €`,
    }))

    const total = countTotal({
      cab, typeId, additionalOpt, biColor, grillclothId, speakersId,
    })
    const showPrice = !!total?.amount

    const devisDataForMail = genDataForEmailDevi({
      cab, typeId, additionalOpt, biColor, grillclothId, speakersId, teintes,
    })
    const devisAsStringForMail = genDevis(
      devisDataForMail.title,
      devisDataForMail.data,
      total?.amount,
      total?.text,
    )

    return (
      <>
        {showPrice && (
        <PricePreviw
          amount={total?.amount}
          text={total?.text}
          openForm={this.onToggleForm}
        />
        )}
        <Dialog show={showForm} onClose={this.onToggleForm}>
          <Form
            title="Envoyer le devis"
            emailSubject="Devis Music Gear"
            withDevis
            devis={devisAsStringForMail}
          />
        </Dialog>
        <PageImage page="cabs" />
        <Content>
          <Description>{description}</Description>
          <DevisTitle>Créez votre devis</DevisTitle>
          <List />
          <Images cab={cab} onChange={this.onChangeCab} />
          {cab && (
          <>
            <Section>
              <TitleSection> Type de cab</TitleSection>
              <Select
                value={typeId}
                options={options}
                onChange={this.onChangeOption}
              />
            </Section>
            <Section>
              <TitleSection>Options supplémentaires</TitleSection>
              {
                additionalOptions.map(opt => (
                  <CheckBoxWrapper key={opt.id}>
                    <Checkbox
                      label={opt.label}
                      checked={additionalOpt.includes(opt.id)}
                      onChange={this.onChangeAdditionalOpt(opt.id)}
                    />
                  </CheckBoxWrapper>
                ))
              }
            </Section>
            <Section>
              <TitleSection>Choisissez la teinte de votre Cab</TitleSection>
              <CheckBoxWrapper>
                <Checkbox
                  label="Teinte bois Bi-color +50€"
                  checked={biColor}
                  onChange={this.onSwitchDoubleTeint}
                />
              </CheckBoxWrapper>
              <Teintes teintes={teintes} onClick={this.onChangeTeint} />
            </Section>
            <Section>
              <TitleSection>Grill Coth</TitleSection>
              <GrillCoth
                selectedId={grillclothId}
                onClick={this.onChangeGrillcloth}
              />
            </Section>
            <Section>
              <TitleSection>Choisissez vos speakers</TitleSection>
              <Speakers
                cab={cab}
                typeId={typeId}
                selectedSpeakersId={speakersId}
                onChange={this.onChangeSpeaker}
              />
            </Section>
          </>
          )}
        </Content>
      </>
    )
  }
}

export default CabsContent
